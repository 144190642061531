<template lang="pug">
  BAlert.notification(
    show
    :dismissible="hasEditPermission"
    variant="warning"
    @dismissed="$emit('read-notifications', notification.group_ids)"
  )
    template(v-slot:default)
      .notification-text(
        :title="extractTranslatedAttribute(notification)"
      ) {{ extractTranslatedAttribute(notification) }}
</template>

<script>
  // misc
  import { extractTranslatedAttribute } from "@/helpers/common"

  export default {
    props: {
      notification: {
        type: Object,
        required: true
      },
      hasEditPermission: {
        type: Boolean,
        default: true
      }
    },

    methods: {
      extractTranslatedAttribute
    }
  }
</script>
<style lang="sass" scoped>
  .notification
    z-index: 1

    &-text
      overflow: hidden
      text-overflow: ellipsis
      white-space: nowrap
      width: calc(100% - 53px)

    ::v-deep
      .close
        width: 53px
</style>
