<template lang="pug">
  .notifications
    TransitionGroup(
      name="fade"
      tag="div"
      class="transition-container"
    )
      div(
        v-for="(notification, index) in shownNotifications"
        :key="notification.id"
      )
        Notification(
          :data-index="index"
          :notification="notification"
          :has-edit-permission="hasEditPermission"
          @read-notifications="$emit('read-notifications', $event)"
        )
    .separator(
      v-if="hiddenNotificationsCount > 0"
      @click="opened = !opened"
    )
      .separator-text
        FaIcon(:icon="arrowIcon")
        span {{ separatorText }}
        FaIcon(:icon="arrowIcon")
</template>

<script>
  // components
  import Notification from "./Notification"

  export default {
    props: {
      notifications: {
        type: Array,
        required: true
      },
      hasEditPermission: {
        type: Boolean,
        default: true
      }
    },

    components: {
      Notification
    },

    data() {
      return {
        opened: false,
        delay: 15
      }
    },

    computed: {
      arrowIcon() {
        return this.opened ? "chevron-up" : "chevron-down"
      },

      shownNotifications() {
        return this.opened ? this.notifications : [this.notifications[0]]
      },

      hiddenNotificationsCount() {
        return Math.max(this.notifications.length - 1, 0)
      },

      showNotificationsText() {
        return this.$t("inventory_management.notifications.show", { count: this.hiddenNotificationsCount })
      },

      hideNotificationsText() {
        return this.$t("inventory_management.notifications.hide")
      },

      separatorText() {
        return this.opened ? this.hideNotificationsText : this.showNotificationsText
      }
    }
  }
</script>
<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/transitions.sass"

  .separator
    display: flex
    align-items: center
    text-align: center

    &-text
      background: $default-white
      padding: 1px 5px 0
      color: $filter-title-color
      font-size: 0.8rem
      height: 20px
      cursor: pointer

      span
        display: inline-block
        margin: 0 5px

  .separator::before,
  .separator::after
    content: ''
    flex: 1
    border-bottom: 1px solid $filter-title-color
</style>
